import googleWalletIcon from '../../public/assets/google-wallet-icon.png';
import appleWalletIcon from '../../public/assets/apple-wallet-icon.png';
import Image from 'next/image';

export default function HowItWorks() {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div>
          <p className="mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl text-center">
            Unlock real-world experiences by connecting your digital assets to Apple and Google
            Wallet
          </p>
        </div>
        <div className="flex flex-row justify-center items-center mt-4">
          <div className="mx-3">
            <Image src={appleWalletIcon} width={44} height={44} />
          </div>
          <div className="mx-3">
            <Image src={googleWalletIcon} width={47} height={40} />
          </div>
        </div>
      </div>
    </div>
  );
}
