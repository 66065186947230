import React from 'react';
import NavHeader from '../ui/layout/NavHeader';
import Hero from '../ui/home/Hero';
import HowItWorks from '../ui/home/HowItWorks';
import FAQ from '../ui/home/FAQ';
import Footer from '../ui/layout/Footer';
import Demo from '../ui/home/Demo';
import Features from '../ui/home/Features';
import SidePanelNew from '../ui/home/SidePanelNew';
import Banner from '../ui/shared/Banner';
import CTA from '../ui/shared/CTA';
import EVMLogos from '../ui/home/EVMLogos';
import Investors from '../ui/home/Investors';
import Partners from '../ui/home/Partners';
export default function App() {
  return (
    <>
      {/* <Banner
        message="📣 We've been acquired by MoonPay!"
        cta={{ label: 'Read More', url: '/case-studies/quantum' }}
      /> */}
      <NavHeader />
      <Hero />
      {/* <HowItWorks /> */}
      <Partners />

      <EVMLogos />
      <SidePanelNew />
      <CTA title="Ready to get started?" message="Request access." buttonText="Contact Us" />
      <Features />
      <Demo />
      <FAQ />
      {/* <Investors /> */}
      <Footer />
    </>
  );
}
