import Image from 'next/image';
import ethereum from '../../public/assets/chains/ethereum-256.svg';
import polygon from '../../public/assets/chains/polygon-256.svg';
import arbitrum from '../../public/assets/chains/arbitrum-256.svg';
import optimism from '../../public/assets/chains/optimism-256.svg';
import binance from '../../public/assets/network-logos/bsc-256.svg';
import gnosis from '../../public/assets/network-logos/gnosis-256.svg';
import base from '../../public/assets/network-logos/base-256.svg';
import hedera from '../../public/assets/network-logos/hedera-256.svg';
import Marquee from 'react-fast-marquee';

export default function EVMLogos() {
  return (
    <div className="flex flex-col items-center bg-gray-50">
      <span className=" font-bold">Current live on 15+ chains and testnets</span>
      <Marquee className="bg-gray-50" speed={30} gradient={true}>
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <>
              {' '}
              <div className="marquee-item bg-[#716b94]">
                <Image height={150} src={ethereum} alt="Ethereum" priority />
              </div>
              <div className="marquee-item bg-[#8247E5]">
                <Image height={150} src={polygon} alt="Polygon" priority />
              </div>
              <div className="marquee-item bg-[#0052FF]">
                <Image height={150} src={base} alt="Base" priority />
              </div>
              <div className="marquee-item bg-[#2D374B]">
                <Image height={150} src={arbitrum} alt="Arbitrum" priority />
              </div>
              <div className="marquee-item bg-[#FF0420]">
                <Image height={150} src={optimism} alt="Optimism" priority />
              </div>
              <div className="marquee-item bg-[#F3BA2F]">
                <Image height={150} src={binance} alt="Binance" priority />
              </div>
              <div className="marquee-item bg-[#04795B]">
                <Image height={150} src={gnosis} alt="Gnosis" priority />
              </div>
              <div className="marquee-item bg-[#000000]">
                <Image height={150} src={hedera} alt="Gnosis" priority />
              </div>
            </>
          ))}
      </Marquee>
    </div>
  );
}
