import {
  AnnotationIcon,
  EmojiHappyIcon,
  LinkIcon,
  LocationMarkerIcon,
  TicketIcon,
  UploadIcon,
} from '@heroicons/react/outline';

const features = [
  {
    id: 1,
    name: 'Branded Passes',
    description: 'Create personalized passes to match your brand.',
    icon: <TicketIcon className="w-6 h-6 text-white" style={{ transform: 'rotate(270deg)' }} />,
  },
  {
    id: 2,
    name: 'Real-Time Updates',
    description:
      'Keep passes updated and drive your customers to take action with real-time updates',
    icon: <UploadIcon className="w-6 h-6 text-white" />,
  },

  {
    id: 3,
    name: 'Notifications',
    description:
      'Send notifications to your customers’ wallet app with updates, offers, or new information',
    icon: <AnnotationIcon className="w-6 h-6 text-white" />,
  },
  {
    id: 4,
    name: 'Location Targeting',
    description:
      'Define up to 10 different GPS location for each pass to notify customers on their lock screen when they are nearby',
    icon: <LocationMarkerIcon className="w-6 h-6 text-white" />,
  },

  {
    id: 5,
    name: 'Multi/Private Chain',
    description:
      'Currently live on Ethereum, Polygon, Optimism, Arbitrum, BSC, Gnosis, Base and Hedera. Easily adaptable to any public or private EVM chain',
    icon: <LinkIcon className="w-6 h-6 text-white" />,
  },
  {
    id: 6,
    name: 'Easy to Integrate',
    description:
      'Unified API to manage, generate, issue, and update passes for Apple and Google Wallet',
    icon: <EmojiHappyIcon className="w-6 h-6 text-white" />,
  },

  // {
  //   id: 7,
  //   name: 'Web Hooks',
  //   description:
  //     'The pass has been successfully generated, unsuccessfully generated, installed or uninstalled for Apple Wallet and Google Pay',
  //   icon: <GlobeAltIcon className="w-6 h-6 text-white" />,
  // },
];

export default function Features() {
  return (
    <div className="bg-indigo-700" id="features">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-4xl font-extrabold text-white tracking-tight">Features</h2>
        <p className="mt-4 max-w-3xl text-xl text-indigo-200">
          Our APIs provide a convenient solution to manage, generate, issue, and update passes with
          one API for Apple and Google Wallet
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:mt-16 lg:grid-cols-2 lg:gap-x-24">
          {features.map((feature) => (
            <div key={feature.name}>
              <div className="mt-6 w-3/4">
                <h3 className="text-2xl font-medium text-white">{feature.name}</h3>
                <p className="mt-2 text-xl text-indigo-200">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
