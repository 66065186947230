import Image from 'next/image';
import heroImage from '../../public/assets/hero.png';
import HowItWorks from './HowItWorks';

export default function Example() {
  return (
    <div>
      <main>
        <div className="pt-6 bg-gray-100 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-1 lg:gap-8">
              <div className="mx-auto max-w-xl px-4 sm:max-w-4xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-4 text-center">
                  <HowItWorks />

                  <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <div className="rounded-md shadow">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          document.getElementById('demo').scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg">
                        Live Demo
                      </a>
                    </div>
                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                      <a
                        href="https://support.moonpay.com/hc/en-gb/requests/new?ticket_form_id=360000166377"
                        id="AdnFR4Wt"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg">
                        Request Access
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 -mb-1.5 mt-8 lg:mt-0 ">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <Image src={heroImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
