import abs from '../../public/assets/partners/abs.png';
import blockbar from '../../public/assets/partners/blockbar.png';
import crossmint from '../../public/assets/partners/crossmint.png';
import lens from '../../public/assets/partners/lens.png';
import Image from 'next/image';
import lacoste from '../../public/assets/partners/lacoste.png';
import rtfkt from '../../public/assets/partners/rtfkt.png';
import tropee from '../../public/assets/partners/tropee.png';
import unlock from '../../public/assets/partners/unlock.png';

export default function Partners() {
  return (
    <div className="bg-gray-50 py-12 sm:py-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-xl font-semibold leading-8 text-gray-900">
          Trusted by brands, communities, and builders you know and love
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={lacoste}
            alt="Lacoste"
            width={158}
            height={30}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={rtfkt}
            alt="RTFKT"
            width={158}
            height={30}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={abs}
            alt="Adam Bomb Squad"
            width={158}
            height={90}
          />
          <Image
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={lens}
            alt="Europa Labs"
            width={158}
            height={80}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={tropee}
            alt="Tropee"
            width={158}
            height={60}
          />

          <Image
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={crossmint}
            alt="Crossmint"
            width={158}
            height={40}
          />
          <Image
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={unlock}
            alt="Unlock"
            width={158}
            height={30}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={blockbar}
            alt="Block Bar"
            width={158}
            height={30}
          />
        </div>
      </div>
    </div>
  );
}
