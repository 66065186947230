/* This example requires Tailwind CSS v2.0+ */
import {
  AnnotationIcon,
  CalendarIcon,
  LocationMarkerIcon,
  LockClosedIcon,
  ReceiptTaxIcon,
  StarIcon,
} from '@heroicons/react/outline';

const transferFeatures = [
  {
    id: 1,
    name: 'Event Tickets',
    description:
      'Make event entry fast and seamless. Create passes for events that you wish to host for specific NFT holders.',
    icon: CalendarIcon,
  },
  {
    id: 2,
    name: 'Membership Access',
    description:
      'Grant members exclusive access to IRL venues such as art galleries, hotels, sports clubs, gyms, and more.',
    icon: LockClosedIcon,
  },
  {
    id: 3,
    name: 'Coupons',
    description: 'Give customers an infinitely-updateable, personalized mobile wallet coupon.',
    icon: ReceiptTaxIcon,
  },
  {
    id: 4,
    name: 'Loyalty Cards',
    description:
      'Easily reward your customers with exclusive perks that can be redeemed at retail shops and restaurants.',
    icon: StarIcon,
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: 'Notifications',
    description:
      'Send push notifications to your customers with updates, offers, or new information.',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Location Targeting',
    description:
      'Define up to 10 different GPS location for each pass to notify customers on their lock screens when they are nearby.',
    icon: LocationMarkerIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true">
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse">
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        {/* <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            A better way to send money
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus magnam voluptatum
            cupiditate veritatis in, accusamus quisquam.
          </p>
        </div> */}

        <div
          className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
          id="use-cases">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Use Cases
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              Engage with your collectors by connecting their NFTs to passes for tickets, loyalty
              cards, or coupons.{' '}
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404">
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img className="relative mx-auto" width={490} src="/assets/use-cases.png" alt="" />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true">
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse">
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Update Passes in Real-Time
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Keep customers updated and drive them to take action with notifications or location
                triggers.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true">
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse">
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <div className="relative mx-auto max-w-24">
                <div className="grid min-h-screen bg-transparent place-items-center">
                  <div className="mx-auto h-[712px] w-[350px] bg-black rounded-[60px] border-[14px] border-black relative overflow-hidden ring ring-indigo-600 shadow-xl">
                    <video playsInline controls={false} autoPlay loop muted>
                      <source src="/assets/video.mp4" type="video/mp4" />
                      <source src="/assets/video.webm" type="video/webm" />
                      <source src="/assets/video.ogv" type="video/ogg" />
                    </video>
                    <div className="absolute top-0 inset-x-0">
                      <div className="mx-auto bg-black h-6 w-40 rounded-b-3xl"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
